import { FC, useState } from "react";
import { Button, Table, Tr, Th, Td, Text, Flex, Spinner, Box } from "@chakra-ui/react";
import { FaExclamationTriangle, FaPrint } from "react-icons/fa";
import {
  CustomedTableBody,
  CustomedTableHead,
  MobileCheckTag,
  MobileCloseTag,
  MobileTableBody,
  StatusBadge,
} from "../..";
import { useInvoicesList } from "../../../../hooks/useInvoicesList";
import {
  IBEInovices,
  IPagination,
  IQueryOptions,
} from "../../../../core/types";
import { daysDifference, restructureDate } from "../../../utils";
import { Pagination } from "../../Pagination";
import { downloadFromUrl } from "../../../../api/http";
import { endpoints } from "../../../../api/endpoints";

export const restructureInvoices = (invoices: IBEInovices) =>
  invoices
    ? invoices.list.map(
      ({
        id,
        TrN_NumerPelny,
        TrN_DataWys_UTS,
        settled,
        settled_left,
        TrN_RazemBrutto,
        TrN_Termin_UTS,
      }) => ({
        id,
        name: TrN_NumerPelny,
        date: restructureDate(TrN_DataWys_UTS),
        status: !!Number(settled),
        settled_left: Number(settled_left),
        amount: TrN_RazemBrutto,
        deadlineDate: TrN_Termin_UTS,
      })
    )
    : [];

const DEFAULT_PAGINATION: IPagination = {
  _limit: 10,
  _page: 1,
  _order: "desc",
};

export const InvoicesTable: FC<IQueryOptions> = ({ filters }) => {
  const [pagination, setPagination] = useState<IPagination>(DEFAULT_PAGINATION);
  const rawInvoices = useInvoicesList({ pagination, filters });
  const invoices = restructureInvoices(rawInvoices.data);

  const handleDownload = (id: string) => {
    return downloadFromUrl(endpoints.invoices.download.replaceAll("{id}", id));
  };

  const handlePageClick = (page: number) => {
    setPagination((oldState) => {
      const newState = { ...oldState, _page: page };
      return newState;
    });
  };

  if (rawInvoices.isLoading) {
    return (
      <Flex justifyContent="center" alignContent="center">
        <Spinner
          thickness="4px"
          speed="0.8s"
          emptyColor="gray.200"
          color="lightPrimary"
          size="xl"
        />
      </Flex>
    );
  }

  return (
    <>
      <Table variant="simple">
        <CustomedTableHead>
          <Tr>
            <Th>Numer dokumentu</Th>
            <Th>Data wystawienia</Th>
            <Th>Kwota brutto</Th>
            <Th>Status</Th>
            <Th>Do zapłaty</Th>
            <Th>Dni po terminie</Th>
            <Th>Pobierz fakturę</Th>
          </Tr>
        </CustomedTableHead>

        <CustomedTableBody>
          {invoices.length ? (
            invoices.map(({ id, name, date, amount, status, settled_left, deadlineDate }) => (
              <Tr key={id + name}>
                <Td>
                  <Text fontSize="14px" color="basicBlack">
                    {name}
                  </Text>
                </Td>
                <Td>
                  <Text fontSize="14px" color="basicBlack">
                    {date}
                  </Text>
                </Td>
                <Td>
                  <Text fontSize="14px" color="darkPrimary">
                    {amount} zł
                  </Text>
                </Td>
                <Td>
                  {status ? (
                    <StatusBadge label="Opłacona" status="active" />
                  ) : (
                    <StatusBadge label="Nieopłacona" status="inactive" />
                  )}
                </Td>
                <Td>
                  {settled_left} zł
                </Td>
                <Td>
                  {!status && daysDifference(deadlineDate) > 0 ? (
                    <Flex
                      flexDir="row"
                      align="center"
                      fontSize="14px"
                      color="darkRed"
                      fontWeight={600}
                    >
                      <FaExclamationTriangle />{" "}
                      <Text ml="15px"> {daysDifference(deadlineDate)} </Text>
                    </Flex>
                  ) : (
                    <Text fontSize="14px" color="darkPrimary" fontWeight={600}>
                      0
                    </Text>
                  )}
                </Td>
                <Td>
                  <Flex
                    flexDir="row"
                    align="center"
                    fontSize="14px"
                    color="darkPrimary"
                  >
                    <Button
                      backgroundColor="transparent"
                      _hover={{ backgroundColor: "transparent" }}
                      onClick={() => { handleDownload(id) }}
                    >
                      <FaPrint />
                      <Text ml="15px">Faktura</Text>
                    </Button>
                  </Flex>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td>Brak danych</Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
            </Tr>
          )}
        </CustomedTableBody>

        <MobileTableBody>
          {invoices.length ? (
            invoices.map(({ id, name, date, amount, status }) => (
              <Tr key={name + date + "mobile"}>
                <Td p="5px 0">
                  <Flex justifyContent={"space-between"} alignItems="center">
                    {status ? <MobileCheckTag /> : <MobileCloseTag />}
                    <Box m="0px auto 0px 10px">
                      <Text fontSize="14px" fontWeight={600}>
                        {name}
                      </Text>
                      <Text fontSize="14px">{date}</Text>
                    </Box>
                    <Text fontSize="16px" color="darkPrimary" fontWeight={600}>
                      {amount} zł
                    </Text>
                    <Flex
                      flexDir="row"
                      align="center"
                      fontSize="14px"
                      color="darkPrimary"
                    >
                      <Button
                        backgroundColor="transparent"
                        _hover={{ backgroundColor: "transparent" }}
                        onClick={() => { handleDownload(id) }}
                      >
                        <FaPrint />
                      </Button>
                    </Flex>
                  </Flex>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td>Brak danych</Td>
            </Tr>
          )}
        </MobileTableBody>
      </Table>

      <Pagination
        totalCount={rawInvoices?.data?.total || 0}
        pageSize={pagination._limit}
        siblingCount={1}
        currentPage={pagination._page}
        onPageChange={handlePageClick}
      />
    </>
  );
};
