import { FC } from "react";
import { Table, Tr, Th, Td, Text, Flex } from "@chakra-ui/react";
import {
  BuyPackageModal,
  CustomedTableBody,
  CustomedTableHead,
  MobileCloseTag,
  MobileTableBody,
  StatusBadge,
} from "../..";
import { fontSize } from "../../../../theme/FontSize";

export interface IDetailedPackage {
  name: string;
  description: string;
  device: string;
  type: string;
  amount: string;
  vat: string;
  status: number;
  selfService: boolean;
  id: string;
  packageid: string;
}

export interface IPackagesTable {
  packages: IDetailedPackage[];
  iptvAccountId?: string;
}

export const PackagesTable: FC<IPackagesTable> = ({
  packages,
  iptvAccountId,
}) => {
  return (
    <Table variant="simple">
      <CustomedTableHead>
        <Tr>
          <Th>Nazwa</Th>
          <Th>Typ</Th>
          <Th>Cena</Th>
          <Th>VAT</Th>
          <Th>Status</Th>
        </Tr>
      </CustomedTableHead>

      <CustomedTableBody>
        {packages.length ? (
          packages.map(
            ({
              id,
              packageid,
              name,
              device,
              type,
              amount,
              vat,
              status,
              selfService,
            }) => (
              selfService === true || status === 1 ?
                <Tr key={id}>
                  <Td>
                    <Text fontSize="14px" fontWeight="600">
                      {name}
                    </Text>
                    <Text fontSize="14px">{device}</Text>
                  </Td>
                  <Td>
                    <Text fontSize="14px">{type}</Text>
                  </Td>
                  <Td>
                    <Text fontSize="14px">{amount} zł / mc</Text>
                  </Td>
                  <Td>
                    <Text>{vat} %</Text>
                  </Td>
                  <Td>
                    {status ? (
                      <StatusBadge status="active" label="Aktywna" />
                    ) : selfService ? (
                      <BuyPackageModal
                        name={name}
                        cost={amount}
                        package_id={packageid}
                        iptvAccountId={iptvAccountId || "0"}
                      />
                    ) : null}
                  </Td>
                </Tr>
                : null
            )
          )
        ) : (
          <Tr>
            <Td>Brak danych</Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
          </Tr>
        )}
      </CustomedTableBody>

      <MobileTableBody>
        {packages.length ? (
          packages.map(({ id, name, amount, packageid, selfService, status }) => (
            selfService === true || status === 1 ?
              <Tr key={id + "mobile"}>
                <Td>
                  <Flex justifyContent={"space-between"} alignItems={'center'} /* background={'yellow'} */ >
                    <Text fontSize="15px" fontWeight={600} w={'55%'} /* background={'pink'} */ >
                      {name}
                    </Text>
                    <Flex justifyContent={"center"} w={'15%'} alignItems={'center'} /* background={'green'} */ >
                      <Text fontSize="14px" fontWeight={600}>
                        {amount} zł
                      </Text>
                    </Flex>
                    <Flex justifyContent={"center"} w={'30%'} alignItems={'center'}>

                      {status ? (
                        <StatusBadge status="active" label="Aktywna" width="70px" style={{ fontSize: '14px' }} />
                      ) : selfService ? (
                        <BuyPackageModal
                          mobileVersion={true}
                          name={name}
                          cost={amount}
                          package_id={packageid}
                          iptvAccountId={iptvAccountId || "0"}
                        />
                      ) : null}
                    </Flex>
                  </Flex>
                </Td>
              </Tr> : null
          ))
        ) : (
          <Tr>
            <Td>Brak danych</Td>
          </Tr>
        )}
      </MobileTableBody>
    </Table>
  );
};
